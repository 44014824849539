import './App.css';
import Form from '@rjsf/core';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

const schema: RJSFSchema = {
  title: 'Todo',
  type: 'object',
  required: ['title'],
  properties: {
    title: { type: 'string', title: 'Title', default: 'A new task' },
    done: { type: 'boolean', title: 'Done?', default: false },
    lastName: { type: "string",title: "Last name" },
      age: {type: "integer",title: "Age"},
      bio: {type: "string",title: "Bio"},
      password: {type: "string",title: "Password",minLength: 3},
      telephone: {type: "string",title: "Telephone",minLength: 10}
  },
};

const log = (type) => console.log.bind(console, type);

// render(
//   <Form
//     schema={schema}
//     validator={validator}
//     onChange={log('changed')}
//     onSubmit={log('submitted')}
//     onError={log('errors')}
//   />,
//   document.getElementById('app')
// );

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <Form
        schema={schema}
        validator={validator}
        onChange={log('changed')}
        onSubmit={log('submitted')}
        onError={log('errors')}
        />
      </header>
    </div>
  );
}

export default App;
